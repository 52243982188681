import { createContext } from "react";
import { useState, useEffect } from "react";
import {
  auth,
  onAuthStateChanged,
  db,
  doc,
  onSnapshot,
} from "../services/firebase";

export const AuthContext = createContext();

//A lot of the methods here are provided by Firebase. The tl;dr is that they created all the functions that are needed to pull the login information (user + session credentials) out of your browser for you.

//When someone logs in + registers, in the same way, firebase saves the creds to a session in your browser. Here, we pull those creds out, and load the user.

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setHasLoaded(true);
    });
  }, []);

  //The point of this is to load the user's information, if the uid exists (it would exist if they are logged in.)
  useEffect(() => {
    //the user object has the user_id (uid). This uid is what we will use for most things.
    if (user && user.uid) {
      //This is assuming that you are storing your user information in a "users" collection. If it is something different, like "accounts", you will need to change that here. What we are doing here is creating a 'path' to your user (/users/{userId}) that can be used with the onSnapshop function, which basically pulls and watches the user database object.
      const userDocRef = doc(db, "users", user.uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          //this is where we save the user object to state. This will be the whole database document. So if you have a field on there called 'score', it will be contained in this object. You could console log userInfo.score and it would show.
          setUserInfo({ uid: user.uid, ...docSnapshot.data() });
        } else {
          console.error("No such document!");
        }
      });

      // Cleanup listener on component unmount
      return () => unsubscribe();
    }
  }, [user]);

  //What we do here is load all of this information into our context. This makes it accessible anywhere else in the app.
  return (
    <AuthContext.Provider
      value={{
        user,
        //we add an extra field called authenticated, just so that we can do the logic once here and then use it anywhere else.
        authenticated: user && user.uid ? true : false,
        hasLoaded,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};