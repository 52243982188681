import React, { useState, useEffect, useContext } from "react";
import EducatedFan from "../images/Educated Fan.png";
import { useForm } from "react-hook-form";
import triviaData from "./DailyTriviaData";
import { AuthContext } from "./contexts";
import { db, doc, updateDoc } from "../services/firebase";
import { Link } from "react-router-dom";
import ScoreWindow from "./ScoreWindow";
import CorrectAnswerList from "./CorrectAnswerList";


const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

function Home() {
  const { userInfo, authenticated } = useContext(AuthContext);
  const [question, setQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [remainingAttempts, setRemainingAttempts] = useState(5);
  const { register, handleSubmit, reset } = useForm();
  const [showScoreWindow, setShowScoreWindow] = useState(false);
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const hasPlayedToday = userInfo?.daysPlayed?.includes(currentDate) ||
   localStorage.getItem('hasPlayedToday') === currentDate;

  useEffect(() => {
    const savedGameData = localStorage.getItem("gameData");
    const savedGameState = localStorage.getItem("gameState");

    if (savedGameData) {
      const gameData = JSON.parse(savedGameData);
      if (gameData.date === currentDate) {
      setAnswers(gameData.answers);
      setRemainingAttempts(gameData.remainingAttempts);
    } 
  } else {
    // No saved game data for today, it's a new day, so reset the state
    setAnswers([]);
    setRemainingAttempts(5);
    }
   
  if (savedGameState) {
    const gameState = JSON.parse(savedGameState);
    if (gameState.date === currentDate) {
    setAnswers(gameState.answers);
    setRemainingAttempts(gameState.remainingAttempts);
  } 
}
  }, [currentDate]);

  useEffect(() => {
    
    const getQuestion = (date) => {
      const todaysQuestion = triviaData.find(
        (triviaItem) => triviaItem.questionDate === date
      );
      if (todaysQuestion) {
        setQuestion(todaysQuestion);
      }
    };
    if (currentDate && !question) {
      getQuestion(currentDate);
    }
  }, [currentDate, question]);

  const renderAnswers = () => {
    if (answers.length) {
      return answers.map((answer) => {
        return (
          <div
            key={answer.answer}
            className={classNames(
              answer.correct ? "bg-green-100" : "bg-red-100",
              "rounded-lg p-2 mb-1"
            )}
          >
            {answer.answer}
          </div>
        );
      });
    } else {
      return null;
    }
  };

  const renderForm = () => {
    if (remainingAttempts > 0 && !hasPlayedToday) {
      return (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-3 space-x-2"
        >
          <input
            className="col-span-2 ring-0 border border-gray-200 focus:ring-0 rounded-lg text-sm px-2 py-2"
            {...register("userAnswer")}
          />
          <button
            className="col-span-1 bg-purple-500 rounded-lg text-white"
            type="submit"
          >
            Submit
          </button>
        </form>
      );
    }
  };

  const renderHasPlayedToday = () => {
    if (hasPlayedToday) {
      return (
        <div className="bg-gray-100 rounded-lg p-2 mb-1 font-semibold text-gray-500">
          You have already played today
        </div>
      );
    } else return null;
  };

  const saveGameData = (answers, numCorrect, currentDate, remainingAttempts) => {
    const gameData = {
      answers: answers,
      scoreToday: numCorrect * 5,
      remainingAttempts: 0,
      date: currentDate 
    };
    localStorage.setItem("gameData", JSON.stringify(gameData));
  };

  const handleGameLogic = async (numCorrect) => {
    if (userInfo) {
    const score = numCorrect * 5;
    const userRef = doc(db, "users", userInfo.uid);
    await updateDoc(userRef, {
      overallScore: userInfo.overallScore + score,
      daysPlayed: userInfo.daysPlayed
        ? [...userInfo.daysPlayed, currentDate]
        : [currentDate],
    });
  }
  };

  const onSubmit = async ({ userAnswer }) => {
    const lowerCaseAnswer = userAnswer.trim().toLowerCase();
    const isCorrect = question.answerOptions
      .map((option) => option.toLowerCase())
      .includes(lowerCaseAnswer);
  
    // Check if the answer is correct and hasn't been submitted before
    const isUniqueCorrectAnswer = isCorrect && !answers.some(answer => answer.answer.toLowerCase() === lowerCaseAnswer);
  
    if (isUniqueCorrectAnswer || !isCorrect) {
      // Always add the new answer to newAnswers, whether it's correct or not.
      const newAnswers = [
        ...answers,
        { answer: userAnswer, correct: isCorrect },
      ];
  
      setAnswers(newAnswers);
      setRemainingAttempts((remainingAttempts) => remainingAttempts - 1);
  
      if (newAnswers.length === 5) {
        // Calculate numCorrect after all 5 answers are in newAnswers.
        const numCorrect = newAnswers.reduce((count, answerObj) => {
          return answerObj.correct === true ? count + 1 : count;
        }, 0);
  
        await handleGameLogic(numCorrect);
        setShowScoreWindow(true);
        localStorage.setItem("hasPlayedToday", currentDate);
  
        saveGameData(newAnswers, numCorrect, currentDate, remainingAttempts);
      } else {
        reset();
      }
      const gameState = {
        answers: newAnswers,
        remainingAttempts: remainingAttempts - 1, // Subtract 1 for the current attempt
      };
      localStorage.setItem("gameState", JSON.stringify(gameState));
    } else {
      // Handle the case where the answer has already been submitted
      // You can show an error message or take appropriate action here
      console.log("Answer has already been submitted");
    }
  };
  

  const handleCloseScoreWindow = () => {
    setShowScoreWindow(false);
  };

  const copyScoreToClipboard = () => {
    if (remainingAttempts === 0) {
    const score = (answers.filter(answer => answer.correct).length * 5)
    const parsedScore = parseInt(score, 10);
  
    const textArea = document.createElement('textarea');
    textArea.value = `My Educated Fan score for today is ${parsedScore}! Check it out at educatedfan.com`;
  
    document.body.appendChild(textArea);
  
    textArea.select();
    document.execCommand('copy');
  
    document.body.removeChild(textArea);
  
    alert('Score copied to clipboard. You can now paste it into your messaging app.');
  }
};

  return (
    <div className="min-h-screen App bg-gradient-to-r from-purple-600 via-purple-300 to-purple-600 p-12 rounded-lg shadow-lg">
      {/* Educated Fan Image and Header */}
      <div className="text-center max-w-screen-md mx-auto">
        <img
          src={EducatedFan}
          alt="My logo"
          className="w-24 h-24 mx-auto mt-2"
        />
        <h1 className="text-4xl font-bold text-white mb-4">Utah Jazz Trivia</h1>

        {/* QUESTION SECTION  */}

        {/* ONLY RENDER QUESTION SECTION IF THE QUESTION EXISTS */}
        {question && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="question text-xl text-gray-800 mb-4 font-bold">
              {question.dailyQuestion}
            </div>
            {renderHasPlayedToday()}
            {renderAnswers()}
            {renderForm()}
            {showScoreWindow && (
              <ScoreWindow
                numCorrect={answers.filter(answer => answer.correct).length * 5}
                userInfo={userInfo?.overallScore || 0}
                onClose={handleCloseScoreWindow}
              />
            )}
            <div>
            {remainingAttempts !== 0 && (
              <div>
            <p className="text-left text-sm italic">(Enter one answer then press submit)</p>
              </div>
              )}
            </div>
            <div>
            {remainingAttempts === 0 && (
              <div>
                <Link className="underline" to='/correctAnswerList'>View Correct Answers</Link>
                </div>
            )}
        </div>
            </div>
        )}
    <div className="font-semibold p-6 mt-5 bg-white rounded-lg shadow-md">
  <p>Remaining Attempts: {remainingAttempts}</p>
  <p>Your Score Today: {answers.filter(answer => answer.correct).length * 5}</p>
  <p className="mb-4">Your Educated Fan Score: {userInfo?.overallScore || 0}</p>
  <div className="mt-4"></div> {/* Add extra space */}
  <div className="mt-6"></div>
  <div className="mt-4"></div> {/* Add extra space */}
  {remainingAttempts === 0 && (
    <button className="rounded-md bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600" onClick={copyScoreToClipboard}>Copy Score</button>
  )}
  <div className="mt-4"></div> {/* Add extra space */}
  {!authenticated && (
    <div>
      <div className="mt-2 mb-2">
        <Link
          to="/registration"
          className="rounded-md bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Register to save your score
        </Link>
      </div>
      <p className="mb-2">Or </p>
      <div className="mt-2 mb-2">
        <Link
          to="/login"
          className="rounded-md bg-purple-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
        >
          Login
        </Link>
      </div>
    </div>
  )}
</div>

      </div>
    </div>
  );
}

export default Home;