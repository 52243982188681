import React, { useState } from 'react';
import { signUp } from '../services/firebase';
import { createUserDocument } from './userService';
import { Link, useNavigate } from 'react-router-dom';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    const result = await signUp({ email, password, username });

    if (result === true) {
      // onSignUp();
      navigate("/")
    } else if (result.error) {
      setError(result.error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignUp();
  }

  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-purple-600 bg-opacity-80">
      <div className="bg-white p-8 rounded shadow-md">
        <h2 className="text-3xl font-bold mb-4">Sign Up</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full px-4 py-2 border rounded mb-2"
            required
          />
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="block w-full px-4 py-2 border rounded mb-2"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full px-4 py-2 border rounded mb-4"
            required
          />
          <button
            type="submit"
            onClick={handleSignUp}
            className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded"
          >
            Sign Up
          </button>
        </form>
        <p className="mt-4">
          Already have an account? <Link 
          to="/login" 
          className="text-blue-500 hover:underline cursor-pointer">Log in</Link>
        </p>
      </div>
    </div>
  );
}

export default SignUp;
