import React, { useEffect, useState } from "react";
import triviaData from "./DailyTriviaData";
import Home from "./Home";
import { Link, useNavigate } from 'react-router-dom';


function CorrectAnswerList () {

    const [correctAnswers, setCorrectAnswers] = useState()
    const currentDate = new Date().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

    useEffect(() => {
    
        const getCorrectAnswers = (date) => {
          const todaysCorrectAnswers = triviaData.find(
            (triviaItem) => triviaItem.questionDate === date
          );
          if (todaysCorrectAnswers) {
            setCorrectAnswers(todaysCorrectAnswers);
          }
        };
        if (currentDate && !correctAnswers) {
          getCorrectAnswers(currentDate);
        }
      }, [currentDate, correctAnswers]);

      return (
        <div className="App bg-gradient-to-r from-purple-600 via-purple-300 to-purple-600 p-16 rounded-lg shadow-lg max-w-screen mx-auto min-h-screen min-w-screen">
          <h1 className="text-white text-xl font-bold mb-4">Correct Answers</h1>
          <ul>
            {correctAnswers?.answerOptions?.map((answer, index) => (
              <li
                key={index}
                className="bg-white p-2 mb-2 rounded-md shadow-sm text-gray-800"
              >
                {answer}
              </li>
            ))}
          </ul>
          <Link className="underline text-white" to="/" >Return To Game</Link>
        </div>
      );
}

export default CorrectAnswerList;