import { getFunctions, httpsCallable } from 'firebase/functions'
import { initializeApp } from 'firebase/app'
import {
  signInWithEmailAndPassword, 
  getAuth, 
  createUserWithEmailAndPassword, 
  updateProfile,
  onAuthStateChanged
} from "firebase/auth";
import { doc, onSnapshot, getFirestore, setDoc, updateDoc, getDoc } from "firebase/firestore";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXddNSzCTY__DG85xpxOIDtojIgN1jU7c",
  authDomain: "jazz-dev-849c6.firebaseapp.com",
  projectId: "jazz-dev-849c6",
  storageBucket: "jazz-dev-849c6.appspot.com",
  messagingSenderId: "885326427343",
  appId: "1:885326427343:web:7c4bcaefb2d9a82f4af3ba",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

const functions = getFunctions(app);

// Sign in funciton
const signIn = async ({ email, password }) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (error) {
    return { error: error.message, code: error.code };
  }
};

const signUp = async ({ email, password, username }) => {
  console.log(email, password, username)
  try {
    const auth = getAuth();
    const userCredential = await 
    createUserWithEmailAndPassword(
      auth, 
      email, 
      password
      );
    const user = userCredential.user;
    await setDoc(doc(db, "users", user.uid), {
      email,
      username,
      createTime: new Date(),
      overallScore: 0,
    });
    return true;
  } catch (error) {
    return { error: error.message, code: error.code };
  }
}; 

export {
    httpsCallable,
    functions,
    signIn,
    signUp,
    doc,
    onSnapshot,
    auth,
    db,
    onAuthStateChanged,
    updateDoc,
    getDoc,
};