

const triviaData = [
    {
        dailyQuestion:'Name 5 Jazz players who have had their jersey retired.' ,
        answerOptions: ['John Stockton', 'Karl Malone', 'Adrian Dantley', 'Pete Maravich', 'Jeff Hornacek', 'Darrel Griffith', 'Mark Eaton'],
        questionDate: '10/04/2023'
    },
    {
        dailyQuestion: "Name 5 of the top 10 players with the most total rebounds for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "Rudy Gobert",
          "Mark Eaton",
          "Derrick Favors",
          "John Stockton",
          "Greg Ostertag",
          "Rich Kelley",
          "Thurl Bailey",
          "Andrei Kirilenko",
          "Paul Millsap"
        ],
        questionDate: "10/05/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players who have played the most games for the Utah Jazz.",
        answerOptions: [
          "John Stockton",
          "Karl Malone",
          "Mark Eaton",
          "Darrell Griffith",
          "Thurl Bailey",
          "Greg Ostertag",
          "Andrei Kirilenko",
          "Derrick Favors",
          "Bryon Russell",
          "Rudy Gobert"
        ],
        questionDate: "10/06/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most free throws made for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Adrian Dantley",
          "Andrei Kirilenko",
          "Rudy Gobert",
          "Gordon Hayward",
          "Thurl Bailey",
          "Pete Maravich",
          "Mehmet Okur",
          "Deron Williams"
        ],
        questionDate: "10/07/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most assists for the Utah Jazz.",
        answerOptions: [
          "John Stockton",
          "Karl Malone",
          "Rickey Green",
          "Deron Williams",
          "Joe Ingles",
          "Andrei Kirilenko",
          "Jeff Hornacek",
          "Pete Maravich",
          "Gordon Hayward",
          "Adrian Dantley"
        ],
        questionDate: "10/08/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most three-point field goals made for the Utah Jazz.",
        answerOptions: [
          "Joe Ingles",
          "Donovan Mitchell",
          "John Stockton",
          "Gordon Hayward",
          "Jordan Clarkson",
          "Bojan Bogdanović",
          "Bryon Russell",
          "Darrell Griffith",
          "Mehmet Okur",
          "Deron Williams"
        ],
        questionDate: "10/09/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most points scored for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Adrian Dantley",
          "Darrell Griffith",
          "Thurl Bailey",
          "Andrei Kirilenko",
          "Pete Maravich",
          "Donovan Mitchell",
          "Gordon Hayward",
          "Rudy Gobert"
        ],
        questionDate: "10/10/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most field goals made for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Darrell Griffith",
          "Adrian Dantley",
          "Thurl Bailey",
          "Pete Maravich",
          "Derrick Favors",
          "Donovan Mitchell",
          "Carlos Boozer",
          "Rudy Gobert"
        ],
        questionDate: "10/11/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most two-point field goals made for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Adrian Dantley",
          "Darrell Griffith",
          "Thurl Bailey",
          "Pete Maravich",
          "Derrick Favors",
          "Carlos Boozer",
          "Rudy Gobert",
          "Rickey Green"
        ],
        questionDate: "10/12/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most three-point field goal attempts for the Utah Jazz.",
        answerOptions: [
          "Donovan Mitchell",
          "Joe Ingles",
          "John Stockton",
          "Jordan Clarkson",
          "Gordon Hayward",
          "Darrell Griffith",
          "Bryon Russell",
          "Deron Williams",
          "Bojan Bogdanović",
          "Mehmet Okur"
        ],
        questionDate: "10/13/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most offensive rebounds for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "Rudy Gobert",
          "Mark Eaton",
          "Derrick Favors",
          "Greg Ostertag",
          "Paul Millsap",
          "Rich Kelley",
          "Andrei Kirilenko",
          "Adrian Dantley",
          "Thurl Bailey"
        ],
        questionDate: "10/14/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most defensive rebounds for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "Rudy Gobert",
          "Mark Eaton",
          "John Stockton",
          "Derrick Favors",
          "Thurl Bailey",
          "Carlos Boozer",
          "Rich Kelley",
          "Mehmet Okur",
          "Andrei Kirilenko"
        ],
        questionDate: "10/15/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most steals for the Utah Jazz.",
        answerOptions: [
          "John Stockton",
          "Karl Malone",
          "Rickey Green",
          "Andrei Kirilenko",
          "Darrell Griffith",
          "Bryon Russell",
          "Jeff Hornacek",
          "Paul Millsap",
          "Joe Ingles",
          "Gordon Hayward"
        ],
        questionDate: "10/16/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most blocks for the Utah Jazz.",
        answerOptions: [
          "Mark Eaton",
          "Andrei Kirilenko",
          "Rudy Gobert",
          "Greg Ostertag",
          "Karl Malone",
          "Thurl Bailey",
          "Derrick Favors",
          "Paul Millsap",
          "Ben Poquette",
          "Rich Kelley"
        ],
        questionDate: "10/17/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most turnovers for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Darrell Griffith",
          "Adrian Dantley",
          "Andrei Kirilenko",
          "Deron Williams",
          "Mark Eaton",
          "Thurl Bailey",
          "Rickey Green",
          "Gordon Hayward"
        ],
        questionDate: "10/18/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most personal fouls for the Utah Jazz.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Mark Eaton",
          "Paul Millsap",
          "Greg Ostertag",
          "Darrell Griffith",
          "Rich Kelley",
          "Derrick Favors",
          "Bryon Russell",
          "Rudy Gobert"
        ],
        questionDate: "10/19/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the most triple-doubles for the Utah Jazz.",
        answerOptions: [
          "Pete Maravich",
          "Mark Eaton",
          "Andrei Kirilenko",
          "Karl Malone",
          "Carlos Boozer",
          "Adrian Dantley",
          "Gail Goodrich",
          "Joe Meriweather",
          "Danny Schayes",
          "Jeff Hornacek"
        ],
        questionDate: "10/20/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the highest field goal percentage for the Utah Jazz.",
        answerOptions: [
          "Rudy Gobert",
          "Adrian Dantley",
          "Carlos Boozer",
          "Derrick Favors",
          "Ronnie Brewer",
          "Adam Keefe",
          "Karl Malone",
          "Paul Millsap",
          "Blue Edwards",
          "John Stockton"
        ],
        questionDate: "10/21/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the highest two-point field goal percentage for the Utah Jazz.",
        answerOptions: [
          "Rudy Gobert",
          "Lauri Markkanen",
          "Adrian Dantley",
          "Royce O'Neale",
          "Ronnie Brewer",
          "Carlos Boozer",
          "John Stockton",
          "Derrick Favors",
          "Donyell Marshall",
          "Blue Edwards"
        ],
        questionDate: "10/22/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 players with the highest three-point field goal percentage for the Utah Jazz.",
        answerOptions: [
          "Jeff Hornacek",
          "Jonas Jerebko",
          "Georges Niang",
          "Randy Foye",
          "Richard Jefferson",
          "Joe Ingles",
          "Thabo Sefolosha",
          "Kyle Korver",
          "George Hill",
          "Trey Burke"
        ],
        questionDate: "10/23/2023"
      },
      {
        dailyQuestion: "Name 5 players who made the NBA All-Star game that played for the Jazz before the year 2000.",
        answerOptions: [
          "Karl Malone",
          "John Stockton",
          "Mark Eaton",
          "Adrian Dantley",
          "Rickey Green",
          "Pete Maravich",
          "Truck Robinson"
        ],
        questionDate: "10/24/2023"
      },
      {
        dailyQuestion: "Name 5 Utah Jazz players that played for the Jazz and the Lakers.",
        answerOptions: [
          "Karl Malone",
          "Bryon Russell",
          "Adrian Dantley",
          "Carlos Boozer",
          "Greg Foster",
          "Jordan Clarkson",
          "Ronnie Price",
          "Gail Goodrich",
          "Danny Schayes",
          "Ron Boone",
          "Fred Roberts",
          "Mel Counts",
          "Derek Fisher",
          "Wesley Matthews",
          "Larry Krystkowiak",
          "Talen Horton-Tucker",
          "Malik Beasley",
          "Jarred Vanderbilt",
          "Mack Calvin",
          "Marty Byrnes",
          "Spencer Haywood",
          "Ed Davis",
          "Tony Brown",
          "Juan Toscano-Anderson",
          "Damian Jones",
          "Stu Lantz",
          "Shaquille Harrison",
          "Rick Roberson",
          "Brad Davis",
          "John Block"
        ],
        questionDate: "10/25/2023"
      },
      {
        dailyQuestion: "Name 5 of the top 10 seasons where the Jazz had the highest win percentage. (Format answer as YYYY-YYYY example:: 1998-1999)",
        answerOptions: [
          "1996-1997",
          "1997-1998",
          "1998-1999",
          "1994-1995",
          "2020-2021",
          "1999-2000",
          "1995-1996",
          "1991-1992",
          "1989-1990",
          "2007-2008"
        ],
        questionDate: "10/26/2023"
      },
      {
        dailyQuestion: "Here are 5 jersey numbers: 00, 12, 45, 13, 23. Name 5 players who wore one of these jersey numbers while they played for the Utah Jazz.",
        answerOptions: [
          "Slick Watts",
          "Greg Ostertag",
          "Jordan Clarkson",
          "E.C. Coleman",
          "Fred Saunders",
          "Marty Byrnes",
          "Brad Davis",
          "Carl Nicks",
          "John Stockton",
          "John Amaechi",
          "Mark Jackson",
          "Keon Clark",
          "Mehmet Okur",
          "Elijah Milsap",
          "Tony Bradley",
          "Jared Butler",
          "Aaron James",
          "Tyrone Corbin",
          "Pete Chilcutt",
          "Devin Brown",
          "Wesley Matthews",
          "Chris Johnson",
          "Toure Murry",
          "Royce O’Neale",
          "Lauri Markkanen",
          "Henry Bibby",
          "Jeff Wilkins",
          "Jeff Cook",
          "Darryl Dawkins",
          "Eric Leckner",
          "Dan O’Sullivan",
          "William Cunningham",
          "Paul Grant",
          "Jack Cooley",
          "Donovan Mitchell"
        ],
        questionDate: "10/27/2023"
      },
      {
        dailyQuestion: "Name 5 Jazz players who also played for the San Antonio Spurs.",
        answerOptions: [
          "Blake Ahearn",
          "Ron Boone",
          "Allan Bristow",
          "Devin Brown",
          "Mack Calvin",
          "Antoine Carr",
          "DeMarre Carroll",
          "Jeff Cook",
          "Tyrone Corbin",
          "Corey Crowder",
          "Boris Diaw",
          "Howard Eisley",
          "Francisco Elson",
          "Rudy Gay",
          "Paul Griffin",
          "Jason Hart",
          "Steve Hayes",
          "Juancho Hernangomez",
          "George Hill",
          "Stephen Howard",
          "Marc Iavaroni",
          "Othyus Jeffers",
          "Richard Jefferson",
          "Larry Krystkowiak",
          "Trey Lyles",
          "Tony Massenburg",
          "Louie Nelson",
          "Steve Novak",
          "Billy Paultz",
          "Fred Roberts",
          "Scott Roth",
          "Robert Smith",
          "Felton Spencer",
          "Malcolm Thomas",
          "Jacque Vaughn",
          "Jerome Whitehead",
          "Jeff Wilkins",
          "Luka Samanic"
        ],
        questionDate: "10/28/2023"
      },
      {
        dailyQuestion:'List 5 players who have recorded a triple-double in a Jazz uniform.',
        answerOptions: ['Pete Maravich, Joe Meriweather, Gail Goodrich, Adrian Dantley, Danny Schayes, Carlos Boozer, Karl Malone, Andrei Kirilenko, Mark Eaton'],
        questionDate: '10/29/2023'
    },
    {
        dailyQuestion: 'List 5 players who played for the Utah Jazz that have been inducted into the Basketball Hall of Fame.',
        answerOptions: ['Gail Goodrich, John Stockton, Karl Malone, Adrian Dantley, Pete Maravich, Walt Bellamy, Spencer Haywood, Bernard King'],
        questionDate: '10/30/2023'
    },
    {
      dailyQuestion: 'Name 5 of the top 10 all time leading rebounders for the Jazz.',
      answerOptions: ['Karl Malone', 'Rudy Gobert', 'Mark Eaton', 'Derrick Favors', 'John Stockton', 'Greg Ostertag', 'Rich Kelley', 'Thurl Bailey', 'Andrei Kirilenko', 'Paul Millsap'],
      questionDate: '10/31/2023'
  },
    
]

export default triviaData;