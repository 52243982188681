// import React, { useState } from 'react'
// import Home from './Routes/Home'
// import Login from './Routes/Login'
// import { BrowserRouter as Router, Route, } from 'react-router-dom';
// import Registration from './Routes/Registration';

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Registration from "./Routes/Registration";
import Home from "./Routes/Home";
import Login from "./Routes/Login";
import { AuthProvider } from "./Routes/contexts";
import correctAnswerList from "./Routes/CorrectAnswerList";
import CorrectAnswerList from "./Routes/CorrectAnswerList";

// We wrap our app in the AuthProvider that we created in our contexts.js File. This allows us to access all of the values in the AuthProvider in our app.
 function App() {
  return (
      <AuthProvider>
  {/*const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (*/}

      {/* before, there was a isLoggedIn value or something along those lines. That was just to show how you can conditionally render things.
      Here, we use routes. This is where the URL changes what page the user sees. Anytime you need a new page, the <Route /> component is how you would handle that.
      
      https://reactrouter.com/en/main/components/routes
      
      *Side note* React Router is going away from this implementation a little bit, more towards hooks like everyone else. This is still fine, especially since it is easier starting out, but just wanted to leave a note about it in case they depricate it at some point in the future.
  if (isLoggedIn) {
    return <Home />
  }
  else {
    return <Router>
    <Login onLogin ={() => setIsLoggedIn(true)} />
    </Router>
  }
      If you have any other routes, you would also want to add them below */}
      <Router>
        <Routes>
          <Route path="/login" Component={Login} />
          <Route path="/registration" Component={Registration} />
          <Route path="/" Component={Home} />
          <Route path="/correctanswerlist" Component={CorrectAnswerList} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App