import React, { useState, useEffect } from "react";
import EducatedFan from '../images/Educated Fan.png'

const ScoreWindow = ({ numCorrect, userInfo, onClose }) => {
  const [showScoreWindow, setShowScoreWindow] = useState(false);

  useEffect(() => {
    if (numCorrect !== null && userInfo !== null) {
      setShowScoreWindow(true);
    }
  }, [numCorrect, userInfo]);

  const handleClose = () => {
    setShowScoreWindow(false);
    onClose();
  };

  return (
    showScoreWindow && (
      <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-700 bg-opacity-80">
        <div className="bg-white p-8 rounded shadow-md">
          <img src={EducatedFan} alt='My logo' className="w-50 h-20 top-0 left-0 mt-2 ml-2"/> 
          <h2 className="text-3xl font-bold mb-4">Score</h2>
          <div className="mb-4">
            <strong>Your Score Today:</strong> {numCorrect}
          </div>
          <div className="mb-4">
            <strong>Your Educated Fan Score:</strong> {userInfo}
          </div>
          <button
            onClick={handleClose}
            className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    )
  );
};


export default ScoreWindow;
