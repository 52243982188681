import React, { useState } from 'react';
import { signIn, signUp } from '../services/firebase';
import { Link, useNavigate } from 'react-router-dom';



function Login() {
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [error, setError] = useState('')
const navigate = useNavigate();

const handleSignIn = async () => {
  try {
    const result = await signIn({email, password})

    if (result === true) {
      
      navigate("/");

    } else if (result.error) {
      setError(result.error);
    }

  } catch (error) {
    console.error('Login error:', error);
  }
};

    const handleSubmit = (e) => {
      e.preventDefault();
      handleSignIn();
    };



    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-purple-600 bg-opacity-80">
      <div className="bg-white p-8 rounded shadow-md">
        <h2 className="text-3xl font-bold mb-4">Login</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit ={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full px-4 py-2 border rounded mb-2"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full px-4 py-2 border rounded mb-4"
            required
          />
          <button
            type="submit"
            onClick={handleSignIn}
            className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded"
          >
            Login
          </button>
        </form>
        <p className="mt-4">
          Don't have an account? <Link to="/registration" className="text-blue-500 hover:underline cursor-pointer">Sign up</Link>
        </p>
        <p className="mt-2">
          Or{" "} <Link
          to="/" 
          className="text-blue-500 hover:underline cursor-pointer">
          skip login
          </Link>{" "}and play as a guest.
        </p>
      </div>
    </div>
  );
}

export default Login